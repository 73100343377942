.main {
    width: 100%;
}
.main form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-top: 2rem;
}
.main form > div {
    width: 100% !important;
}
.main form button {
    width: fit-content !important;
}

@media screen and (max-width: 568px) {
    .main form button {
        width: 100% !important;
    }
}
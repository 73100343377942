.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
}

/* HOME */
.home {
    width: 100%;
    display: flex;
    justify-content: center;
}
.homeContainer {
    flex-direction: row !important;
    position: relative;
    height: 100vh;
}
.homeInfo {
    width: 400px;
    z-index: 1;
}
.homeInfo h1 {
    width: 100%;
}
.homeInfo p {
    margin-top: 30px;
}
.homeContainer img {
    width: 80%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}
.buyButt {
    margin-top: 50px !important
}

/* ABOUT */
.about {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 125px;
    margin-bottom: 70px;
}
.aboutContainer {
    flex-direction: row !important;
    justify-content: space-between;
    gap: 64px;
}
.me {
    width: 50%;
    position: relative;
}
.mom {
    width: 100%;
}
.emoji {
    right: -80px;
    top: -35px;
    position: absolute;
}
.aboutMeText {
    background-color: #E9EFF5;
    border-radius: 30px;
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    position: relative;
    top: -90px;
}
.aboutInfo {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* VIDEO SECTION */
.videoSection {
    width: 100%;
    padding: 100px 0;
    box-sizing: border-box;
    background-color: #E9EFF5;
    display: flex;
    justify-content: center;
}
.videoSectionContainer {
    position: relative;
    max-width: 667px !important;
    padding: 0 !important;
}
.videoTitle {
    max-width: 457px;
    text-align: center;
}
.videoArrow {
    position: absolute;
    right: -150px;
    top: 40px;
}
.videoPreview {
    width: 100%;
    cursor: pointer;
    margin-top: 35px;
}
.eyes {
    z-index: 1;
    left: -100px;
    position: absolute;
    bottom: -40px;
}
.videoSection strong {
    font-family: "Mazzard Extra Bold" !important;
}

/* HOW */
.how {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.how h2 {
    margin-bottom: 76px;
}
.howContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.howField {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 96px;
}
.howBlock {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
}
.howBlock img {
    width: 100%;
}

/* DOBIRKA */
.dobirka {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 130px;
    box-sizing: border-box;
}
.dobirkaContainer {
    padding: 0 !important;
}
.steps {
    width: 100%;
    margin-top: 46px;
}

/* LEVELS */
.levels {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 180px;
}
.levels h2 {
    margin-bottom: 60px;
}
.levelsText {
    max-width: 370px;
    text-align: center;
    margin-bottom: 20px;
}
.levelsSub {
    font-weight: bold;
}
.levelsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.levelsInfo {
    align-self: flex-start;
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    gap: 102px 37px;
    margin-top: 70px;
}
.levelsBlock {
    width: 42%;
    display: flex;
    align-items: flex-end;
    gap: 20px;
}
.levelsBlockInfo {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.levelsImg {
    position: absolute;
    right: 0;
    bottom: -100px;
    z-index: 0;
}
.hard {
    width: 100px;
}
.hard1 {
    height: 50px;
    background-color: #35A52D;
}
.hard2 {
    height: 77px;
    background-color: #FFD005;
}
.hard3 {
    height: 106px;
    background-color: #E17500;
}
.hard4 {
    height: 148px;
    background-color: #DA553B;
}

/* WHO */
.who {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 170px;
}
.whoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.whoInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    gap: 2.5rem;
}
.whoBlock {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 30px;
}
.whoText {
    display: flex;
    flex-direction: column;
    gap: 19px;
}
.whoText h3 {
    text-transform: uppercase;
}

/* FEATURES */
.features {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 170px;
}
.features h2 {
    text-align: center;
    max-width: 1000px;
}
.features h2 span {
    color: #2A619B !important;
}
.featuresContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.featuresField {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-top: 90px;
}
.featuresPoints {
    width: 50%;
    display: flex;
    flex-direction: column;
}
.feature {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 8px 0;
}
.feature p {
    padding-bottom: 12px;
    border-bottom: 1px solid #E9EFF5;
    width: 100%;
}
.featuresImg {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.featureMa {
    width: 100%;
    height: 377px;
}
.featureEye {
    position: absolute;
    z-index: 1;
    left: -25px;
    top: -25px;
    width: 75px;
}

/* PLACE */
.place {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #E9EFF5;
    padding: 100px 0;
    text-align: center;
}
.placeWrapper {
    margin-top: 53px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.placeItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
}
.placeItem p {
    max-width: 160px;
}

/* RECOMENDATIONS */
.recomendations {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 165px;
}
.recWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 85px;
}
.rec {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.rec button {
    width: 300px !important;
    position: relative !important;
    top: -30px !important;
    font-size: 18px !important;
}

/* REVIEWS */
.reviewHeader {
    margin-top: 150px;
    margin-bottom: 40px;
}
.reviews {
    width: 100%;
    height: 692px;
    background-image: url("../../Assets/review_bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.reviewsContainer {
    position: relative !important;
    height: 100%;
}
.review {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    cursor: pointer;
}
.reviewImgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.reviewImgContainer span {
    opacity: 0;
    position: absolute;
    font-size: 70px;
    z-index: 1;
    color: #0A3364;
    transition-duration: .3s;
}
.review:hover img {
    opacity: .6;
}
.review:hover span {
    opacity: 1;
}
.review img {
    transition-duration: .3s;
}
.rev1 {
    left: 50px;
    top: -20px;
}
.rev1 img {
    width: 163px;
    height: 163px;
}

.rev2 {
    bottom: 100px;
    left: 150px;
}
.rev2 img {
    width: 135px;
    height: 135px;
}

.rev3 {
    left: 30%;
    top: 30%;
}
.rev3 img {
    width: 192px;
    height: 192px;
}

.rev4 {
    bottom: -10px;
    left: 40%;
}
.rev4 {
    width: 150px;
    height: 150px;
}

.rev5 {
    left: 65%;
    top: 55%;
}
.rev5 img {
    width: 136px;
    height: 136px;
}

.rev6 {
    left: 68%;
    top: 50px;
}
.rev6 img {
    width: 150px;
    height: 150px;
}

.rev7 {
    right: 50px;
    bottom: 10%;
}
.rev7 img {
    width: 185px;
    height: 185px;
}

/* BUY */
.buy {
    width: 100%;
    margin-top: 165px;
    display: flex;
    justify-content: center;
}
.buyContainer {
    flex-direction: row !important;
    align-items: flex-start !important;
}
.buyLeft {
    width: 55%;
    display: flex;
    flex-direction: column;
}
.price {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 25px;
    margin-top: 8px;
}
.oldPrice {
    font-family: "Mazzard Light";
    font-size: 40px;
    color: #0A3364;
    text-decoration: line-through;
}
.newPrice {
    font-family: "Mazzard Bold";
    font-size: 40px;
    color: #0A3364;
}
.buyLeft button {
    width: fit-content !important;
}
.buyRight {
    width: 45%;
    padding-left: 60px;
    box-sizing: border-box;
    padding-top: 15px;
}
.buyRightTitle {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 18px;
    position: relative;
}
.gift {
    position: absolute;
    left: -50px;
    top: 0px;
}

/* FOOTER */
.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../Assets/foot_back.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    padding-top: 150px;
    box-sizing: border-box;
    text-align: center;
    color: white;
    margin-top: 100px;
}
.footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 650px;
    width: 100%;
    color: white !important;
}
.footerTitle {
    color: white;
    display: flex;
    gap: 10px;
}
.footText {
    color: white;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}
.rules {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-bottom: 2rem;
}
.rules a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    font-family: "Mazzard Medium";
}
.rules a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1170px) {
    .main {
        margin-top: 10rem;
    }
    .homeContainer {
        flex-direction: column !important;
        height: fit-content;
    }
    .homeContainer img {
        position: relative;
        top: -70px;
    }
    .homeInfo {
        text-align: center;
        align-items: center;
        width: fit-content;
    }
    .homeInfo h1 {
        width: 100%;
    }
    .recWrapper {
        flex-direction: column;
    }
    .buyRight {
        padding-left: 30px;
    }
    .rev5 {
        left: 56%;
    }
}

@media screen and (max-width: 1000px) {
    .aboutContainer {
        flex-direction: column !important;
        gap: 0;
    }
    .me {
        width: 100%;
    }
    .aboutMeText {
        top: -65px;
    }
    .emoji {
        bottom: 0px;
        z-index: 2;
        top: unset;
        left: -40px;
    }
    .aboutInfo {
        width: 100%;
    }
    .placeWrapper {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
    .videoArrow {
        right: -40px;
        top: 45px;
        width: 190px
    }
    .eyes {
        left: -20px;
    }
    .buyContainer {
        flex-direction: column !important;
        gap: 50px;
    }
    .buyLeft {
        width: 100%;
        text-align: center;
        align-items: center;
    }
    .buyRight {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    .buyRight img {
        align-self: center;
    }
    .price {
        justify-content: center;
    }
}

@media screen and (max-width: 1024px) {
    .main h2 {
        text-align: center;
    }
    .levelsInfo {
        width: 100%;
    }
    .levelsBlock {
        width: 100%;
    }
    .levelsImg {
        display: none;
    }
    .whoInfo {
        flex-direction: column;
    }
    .whoBlock {
        width: 100%;
    }
    .featuresField {
        flex-direction: column;
    }
    .featuresPoints {
        width: 100%;
    }
    .featuresImg {
        width: 100%;
    }
    .featureMa {
        height: 450px;
    }
}

@media screen and (max-width: 568px) {
    .homeInfo h1 {
        font-size: 50px;
        line-height: 55px;
    }
    .main h2 {
        text-align: center;
    }
    .homeContainer img {
        width: 100%;
        top: -25px;
    }
    .about {
        margin-top: 0px;
        margin-bottom: 80px;
    }
    .dobirka {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .dobirkaContainer {
        padding: 0 !important;
        align-items: center !important;
        text-align: center;
    }
    .dobirkaContainer h2 {
        padding: 1rem;
    }
    .videoSection {
        padding: 50px 3rem;
        box-sizing: border-box;
    }
    .recomendationsContainer {
        overflow: hidden;
    }
    .howField {
        flex-direction: column;
        margin-bottom: 32px;
    }
    .levelsImg {
        display: block;
        position: relative;
        width: 150%;
        top: -20px;
        left: -20px;
    }
    .levelsInfo {
        gap: 42px;
    }
    .levels {
        margin-bottom: 80px;
    }
    .who {
        margin-bottom: 80px;
    }
    .whoContainer {
        padding-left: 0 !important;
    }
    .whoContainer h2 {
        padding-left: 1rem;
    }
    .whoBlock {
        position: relative;
        left: -30px;
        width: calc(100% + 30px);
    }
    .features {
        margin-top: 80px;
    }
    .featureMa {
        height: auto
    }
    .whoBlock img {
        width: 150px;
    }
    .reviews {
        min-height: 940px;
    }
    .featuresField {
        margin-top: 40px;
    }
    .rev1 {
        left: 50px;
        top: -30px;
    }
    .rev1 img {
        width: 113px;
        height: 113px;
    }

    .rev2 {
        right: 50px;
        top: 50px;
        left: unset;
    }
    .rev2 img {
        width: 160px;
        height: 160px;
    }

    .rev3 {
        left: 90px;
        top: 220px;
    }
    .rev3 img {
        width: 87px;
        height: 87px;
    }

    .rev4 {
        left: unset;
        right: 0px;
        top: 280px;
    }
    .rev4 img {
        width: 100px;
        height: 100px;
    }

    .rev5 {
        left: 50px;
        top: 380px;
    }
    .rev5 img {
        width: 135px;
        height: 135px;
    }

    .rev6 {
        right: 70px;
        top: 470px;
    }
    .rev6 img {
        width: 113px;
        height: 113px;
    }

    .rev7 {
        left: 20%;
        top: 650px;
    }

    .buyRight {
        padding-left: 3rem;
        box-sizing: border-box;
    }
    .book {
        width: 100%;
        position: relative;
        left: -1rem;
    }
    .footer {
        margin-top: 0;
        height: fit-content;
    }
    .footerTitle img {
        display: none;
    }
    .footerContainer {
        padding: 0 1rem;
        box-sizing: border-box;
    }
    .footText {
        margin-top: 2rem;
    }
    .footLogo {
        width: 70%;
    }
    .rules {
        flex-direction: column;
        text-align: center;
        box-sizing: border-box;
        gap: 1rem;
    }
    .rules a {
        font-size: 14px;
    }
    .reviewHeader {
        margin-top: 80px;
        margin-bottom: 4rem;
    }
    .buy {
        margin-top: 80px;
    }
    .eyes {
        left: -60px;
        width: 70px;
    }
    .videoArrow {
        width: 100px;
        top: 60px;
    }
    .place {
        padding: 80px 0;
    }
    .recomendations {
        margin-top: 80px;
    }
    .reviewHeader {
        text-align: center;
    }
    .price {
        margin-top: 2rem;
    }
    .oldPrice, .newPrice {
        font-size: 30px;
    }
}

@media screen and (max-width: 400px) {
    .reviews {
        min-height: 900px;
    }
    .buyButt {
        width: 182px !important;
        height: 48px !important;
    }
    .rev1 {
        left: 10%;
        top: 0px;
    }
    .rev2 {
        right: 3%;
    }
    .rev3 {
        left: 10%;
    }
    .rev4 {
        right: 10%;
    }
    .rev7 {
        left: 10%;
        right: unset;
        top: 600px;
    }
}

@media screen and (max-width: 350px) {
    .videoArrow {
        top: 80px;
    }
    .rev4 {
        left: unset;
        right: 3%;
    }
    .rev5 {
        left: 3%;
    }
    .buy {
        overflow: hidden;
    }
}
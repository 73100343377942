.p1, .p2 {
    font-family: "Mazzard SemiBold";
    color: #212121;
}
.p1 {
    font-size: 25px;
    line-height: 29.55px;
}
.p2 {
    font-size: 18px;
    line-height: 21.28px;
    font-weight: 500;
}

@media screen and (max-width: 568px) {
    .p1 {
        font-size: 20px;
        line-height: 25px;
    }
    .p2 {
        font-size: 16px;
        line-height: 18px;
    }
}
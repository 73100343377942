.main {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #E9EFF5;
}
.main h2 {
    color: #212121 !important;
}
.container {
    flex-direction: row !important;
}
.info {
    width: 70%;
    z-index: 1;
}
.text {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 1rem;
    margin-bottom: 3rem;
}
.book {
    width: 50%;
    position: absolute;
    right: 0;
}
.likeMobile {
    display: none;
}

@media screen and (max-width: 1170px) {
    .main {
        height: fit-content;
    }
    .container {
        flex-direction: column !important;
        align-items: center !important;
        text-align: center;
        padding-top: 10rem;
        overflow: hidden;
    }
    .info {
        width: 100%;
    }
    .book {
        position: relative;
        width: 150%;
        left: -20px;
    }
    .like {
        display: none;
    }
    .likeMobile {
        display: block;
        margin-bottom: 1rem;
    }
}
.main {
    width: 100%;
    max-width: 1440px;
    padding: 0 100px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 1170px) {
    .main {
        padding: 0 50px;
    }
}

@media screen and (max-width: 568px) {
    .main {
        padding: 0 1rem;
    }
}
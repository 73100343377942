.main {
    background-color: #2A619B !important;
    color: white !important;
    font-size: 20px !important;
    text-transform: initial !important;
    font-weight: 700 !important;
    border-radius: 30px !important;
    padding: 16px 78px !important;
    box-sizing: border-box !important;
    font-family: "Mazzard Bold" !important;
    min-height: unset !important;
    min-width: unset !important;
}

@media screen and (max-width: 568px) {
    .main {
        font-size: 16px !important;
    }
}
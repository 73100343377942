.h1, .h2, .h3 {
    font-family: "VAG World";
    font-weight: 700;
}

.h1 {
    font-size: 70px;
    line-height: 75.94px;
    color: #212121;
}
.h2 {
    font-size: 40px;
    line-height: 51.2px;
    color: #0A3364;
    text-transform: uppercase;
}
.h3 {
    font-size: 25px;
    line-height: 25px;
    color: #212121;
    font-weight: 400;
}

@media screen and (max-width: 568px) {
    .h1 {
        font-size: 50px;
        line-height: 55px;
    }
    .h2 {
        font-size: 30px;
        line-height: 35px;
    }
}

@media screen and (max-width: 350px) {
    .h1 {
        font-size: 40px;
        line-height: 45px;
    }
    .h2 {
        font-size: 25px;
        line-height: 28px;
    }
}
@font-face {
    font-family: 'VAG World';
    src: url('./Assets/Fonts/vag_world_bold.eot'); /* IE9 Compat Modes */ 
    src: url('./Assets/Fonts/vag_world_bold.eot#iefix') format('embedded-opentype'),
         url('./Assets/Fonts/vag_world_bold.woff') format('woff'),
         url('./Assets/Fonts/vag_world_bold.woff2') format('woff2'),
         url('./Assets/Fonts/vag_world_bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard Bold';
    src: url('./Assets/Fonts/MazzardM-Bold.eot'); /* IE9 Compat Modes */ 
    src: url('./Assets/Fonts/MazzardM-Bold.eot#iefix') format('embedded-opentype'),
         url('./Assets/Fonts/MazzardM-Bold.woff') format('woff'),
         url('./Assets/Fonts/MazzardM-Bold.woff2') format('woff2'),
         url('./Assets/Fonts/MazzardM-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard Extra Bold';
    src: url('./Assets/Fonts/MazzardM-ExtraBold.eot'); /* IE9 Compat Modes */ 
    src: url('./Assets/Fonts/MazzardM-ExtraBold.eot#iefix') format('embedded-opentype'),
         url('./Assets/Fonts/MazzardM-ExtraBold.woff') format('woff'),
         url('./Assets/Fonts/MazzardM-ExtraBold.woff2') format('woff2'),
         url('./Assets/Fonts/MazzardM-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard Extra Light';
    src: url('./Assets/Fonts/MazzardM-ExtraLight.eot'); /* IE9 Compat Modes */ 
    src: url('./Assets/Fonts/MazzardM-ExtraLight.eot#iefix') format('embedded-opentype'),
         url('./Assets/Fonts/MazzardM-ExtraLight.woff') format('woff'),
         url('./Assets/Fonts/MazzardM-ExtraLight.woff2') format('woff2'),
         url('./Assets/Fonts/MazzardM-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard Light';
    src: url('./Assets/Fonts/MazzardM-Light.eot'); /* IE9 Compat Modes */ 
    src: url('./Assets/Fonts/MazzardM-Light.eot#iefix') format('embedded-opentype'),
         url('./Assets/Fonts/MazzardM-Light.woff') format('woff'),
         url('./Assets/Fonts/MazzardM-Light.woff2') format('woff2'),
         url('./Assets/Fonts/MazzardM-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard Medium';
    src: url('./Assets/Fonts/MazzardM-Medium.eot'); /* IE9 Compat Modes */ 
    src: url('./Assets/Fonts/MazzardM-Medium.eot#iefix') format('embedded-opentype'),
         url('./Assets/Fonts/MazzardM-Medium.woff') format('woff'),
         url('./Assets/Fonts/MazzardM-Medium.woff2') format('woff2'),
         url('./Assets/Fonts/MazzardM-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard Regular';
    src: url('./Assets/Fonts/MazzardM-Regular.eot'); /* IE9 Compat Modes */ 
    src: url('./Assets/Fonts/MazzardM-Regular.eot#iefix') format('embedded-opentype'),
         url('./Assets/Fonts/MazzardM-Regular.woff') format('woff'),
         url('./Assets/Fonts/MazzardM-Regular.woff2') format('woff2'),
         url('./Assets/Fonts/MazzardM-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard SemiBold';
    src: url('./Assets/Fonts/MazzardM-SemiBold.eot'); /* IE9 Compat Modes */ 
    src: url('./Assets/Fonts/MazzardM-SemiBold.eot#iefix') format('embedded-opentype'),
         url('./Assets/Fonts/MazzardM-SemiBold.woff') format('woff'),
         url('./Assets/Fonts/MazzardM-SemiBold.woff2') format('woff2'),
         url('./Assets/Fonts/MazzardM-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mazzard Thin';
    src: url('./Assets/Fonts/MazzardM-Thin.eot'); /* IE9 Compat Modes */ 
    src: url('./Assets/Fonts/MazzardM-Thin.eot#iefix') format('embedded-opentype'),
         url('./Assets/Fonts/MazzardM-Thin.woff') format('woff'),
         url('./Assets/Fonts/MazzardM-Thin.woff2') format('woff2'),
         url('./Assets/Fonts/MazzardM-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
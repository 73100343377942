.window {
    background: #FFFFFF;
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    box-sizing: border-box;
}
.header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 23px;
    box-sizing: border-box;
    padding-bottom: 0;
}
.header button {
    color: #0A3364 !important;
    font-size: 28px !important;
}
.body {
    padding: 80px;
    padding-top: 2rem;
    box-sizing: border-box;
}

@media screen and (max-width: 568px) {
    .window {
        width: 95%;
    }
    .body {
        padding: 2rem;
    }
}
.main {
    width: 487px;
    position: relative;
    padding: 40px 75px;
    box-sizing: border-box;
    background: #E9EFF5;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}
.emoji {
    position: absolute;
    right: -50px;
    top: -50px;
}
.time {
    display: flex;
    align-items: center;
    margin-top: 44px;
}
.count {
    width: 151px;
    position: relative;
    padding: 15px 35px;
    padding-top: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 30px;
}
.count p {
    position: relative;
    top: -20px;
}
.count span {
    font-family: "Mazzard Bold";
    font-size: 70px;
}
.bullets {
    font-size: 70px;
    animation: blink 1s infinite;
    margin: 0 1rem;
    position: relative;
    top: -6px;
}

@keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@media screen and (max-width: 568px) {
    .main {
        width: 100%;
        padding: 40px 1rem;
    }
    .emoji {
        width: 80px;
        right: -1rem;
        top: -1rem;
    }
    .count {
        padding: 10px 1rem;
        width: 120px;
    }
    .count span {
        font-size: 50px;
        position: relative;
        top: -5px;
    }
    .bullets {
        font-size: 50px;
    }
}

@media screen and (max-width: 350px) {
    .emoji {
        right: -2rem;
        top: -2rem;
    }
    .count {
        width: 100px;
    }
}
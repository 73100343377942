.main {
    width: 650px;
}
.body {
    border-radius: 30px;
    margin-top: 2rem;
    padding: 2rem;
    box-sizing: border-box;
}
.body img {
    width: 100%;
}

@media screen and (max-width: 764px) {
    .main {
        width: 100%;
    }
}
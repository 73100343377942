.main {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 40px;
    z-index: 1;
}
.container {
    align-items: flex-start !important;
}
.main img {
    width: 190px;
}

@media screen and (max-width: 1170px) {
    .main {
        /* position: relative; */
    }
    .container {
        align-items: center !important;
    }
}

@media screen and (max-width: 400px) {
    .main {
        padding-top: 2rem;
    }
    .main img {
        width: 140px;
    }
}